<template>
  <v-card>
    <v-card-title class="accent">
      Maiores Dores
      <v-spacer></v-spacer>

      <v-switch
        v-model="empreendedores"
        class="mt-0 pt-0 mr-3"
        hide-details
        label="Empreendedores"
        @change="getMaioresDores"
      >
      </v-switch>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="getMaioresDores"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="maioresDores.length">
      <v-data-table
        :headers="headers"
        :items="maioresDores"
        :loading="loading"
        hide-default-footer
        disable-sort
        class="data-tables"
      >
      </v-data-table>
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhuma Dispositivo Registrado
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api/axios_service";

export default {
  name: "BoxMaioresDores",

  data() {
    return {
      maioresDores: [],
      empreendedores: false,
      loading: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Quantidade",
          value: "qtde",
          align: "right",
        },
      ];
    },
  },

  methods: {
    async getMaioresDores() {
      this.loading = true;
      this.maioresDores = [];

      const { data } = await api.get(
        `/graficos/box-maiores-dores/?empreendedor=${this.empreendedores}`
      );
      this.maioresDores = data.result;

      this.loading = false;
    },
  },

  mounted() {
    this.getMaioresDores();
  },
};
</script>

<style></style>
